<template>
  <div style="width: 100%">
    <v-select v-model="facility" class="w-50 rounded" style="background: #fff" :options="facilityOptions"
      @input="changeFacility" :disabled="facilityOptions.length === 1" label="name" />
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import ToastNotification from "@/components/ToastNotification";

import axiosU from "@/services/auth";

export default {
  components: {
    vSelect,
    ToastNotification
  },
  data() {
    return {
      facilityOptions: [],
      facility: null,
      setTimeoutBuscador: "",
      pusherChannel: null,
    };
  },
  created() {
    if (!this.pusherChannel) {
      this.subscribeToPusher();
    }
  },
  destroyed() {
    this.$pusher.unsubscribe(
      `session_facility_user.${JSON.parse(localStorage.getItem("userData")).id}`
    );
  },
  mounted() {
    this.facility = JSON.parse(atob(localStorage.getItem("Facility")));
    this.$variableGlobal.isMedicalCenter = this.facility.insurance

    this.facilityOptions = JSON.parse(
      atob(localStorage.getItem("setFacilityOptions"))
    );
  },
  methods: {
    changeFacility(item) {
      // this.$pusher.unsubscribe(`presence-officeVisit.${JSON.parse(atob(localStorage.getItem("Facility"))).id}`);
      const datos = {
        facilities_id: item.id,
      };
      axiosU.selectFacility(datos).then(({ facilities_id, ...res }) => {
        if (res.status == "error") return this.$refs.toast.danger(res.message);
        this.$variableGlobal.facility = item;
        this.$variableGlobal.isMedicalCenter = this.facility.insurance

        localStorage.setItem("facility", facilities_id);
        localStorage.setItem("Facility", btoa(JSON.stringify(this.facility)));
        // this.$pusher.subscribe(`presence-officeVisit.${JSON.parse(atob(localStorage.getItem("Facility"))).id}`);
      }).catch((error) => {
        this.$refs.toast.danger(error.message);
      });
    },
    subscribeToPusher() {
      this.pusherChannel = this.$pusher.subscribe(
        `session_facility_user.${JSON.parse(localStorage.getItem("userData")).id}`
      );

      this.pusherChannel.bind("changeFacilityEvent", ({ event }) => {
        const facility = this.facilityOptions.find(item => item.id === parseInt(event.decode_facility));


        if (event.type === "change_facility" && event.user_id === JSON.parse(localStorage.getItem("userData")).id) {
					console.log(event);
          this.$variableGlobal.facility = facility;
          this.$variableGlobal.isMedicalCenter = facility.insurance;
          localStorage.setItem("facility", event.facility_id);
          localStorage.setItem("Facility", btoa(JSON.stringify(facility)));
          this.facility = facility;
        }

      });
    }
  },
};
</script>

<style></style>
<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="generalData.avatar"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @change="onFileChange($event)"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6" class="d-lg-none">
          <label for="">Facility</label>
          <SelectFacility class="w-100"/>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Name" label-for="account-username">
            <b-form-input
              v-model="generalData.name"
              placeholder="name"
              name="name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Last Name" label-for="account-name">
            <b-form-input
              v-model="generalData.lastname"
              name="name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input
              v-model="generalData.email"
              name="email"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Identification" label-for="account-company">
            <b-form-input
              v-model="generalData.identification"
              name="company"
              disabled
              placeholder="Identification"
            />
          </b-form-group>
        </b-col>

        <!-- alert -->
        <b-col cols="12" class="mt-75">
          <b-alert show variant="warning" class="mb-50">
            <h4 class="alert-heading">
              Your email is not confirmed. Please check your inbox.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link"> Resend confirmation </b-link>
            </div>
          </b-alert>
        </b-col>
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="saveChanges"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <ToastNotification ref="toast" />
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

import axiosU from "@/services/admin/user";

import ToastNotification from "@/components/ToastNotification";
import axiosEC from "@/services/external-settings/images";
import SelectFacility from "@/components/SelectFacility";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ToastNotification,
    SelectFacility,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profileFile: null,
      fileResource: null,
      changePhoto: false,
    };
  },
  methods: {
    resetForm() {
      this.generalData = this.generalData;
    },
    async saveChanges() {
      if (this.changePhoto) {
        const photoUrl = await this.uploadPhoto(this.fileResource);
        this.generalData.avatar = photoUrl;
        this.changePhoto = false;
      }
      axiosU
        .userUpdate(this.generalData.id, this.generalData)
        .then(() => {
          this.$refs.toast.success("User updated successfully");
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          this.$refs.toast.error("Error updating user");
        });
    },
    onFileChange(event) {
      if (event.target.files[0]) {
        this.fileResource = event.target.files[0];
        this.generalData.avatar = URL.createObjectURL(this.fileResource);
        this.changePhoto = true;
      } else {
        this.fileResource = null;
        this.generalData.avatar = null;
        this.changePhoto = false;
      }
      //returns an array of files even though multiple not used
    },
    async uploadPhoto(file) {
      const formData = new FormData();
      formData.append("type", "USER");
      formData.append("file", file);
      const { dir_img } = await axiosEC.createImage(formData);
      return dir_img;
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>

<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- bio -->
        <!-- <b-col cols="12">
          <b-form-group
            label="Bio"
            label-for="bio-area"
          >
            <b-form-textarea
              id="bio-area"
              v-model="informationData.bio"
              rows="4"
              placeholder="Your bio data here..."
            />
          </b-form-group>
        </b-col> -->
        <!--/ bio -->

        <!-- birth date -->
        <b-col md="6">
          <b-form-group label-for="example-datepicker" label="Birth date">
            <flat-pickr
              v-model="informationData.date_birth"
              :config="config"
              class="form-control"
              name="date"
              placeholder="Birth date"
            />
          </b-form-group>
        </b-col>
        <!--/ birth date -->

        <!-- Country -->
        <b-col md="6">
          <b-form-group label-for="countryList" label="Country">
            <v-select
              id="countryList"
              v-model="informationData.cities_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              disabled
            />
          </b-form-group>
        </b-col>
        <!--/ Country -->

        <!-- website -->
        <b-col md="6">
          <b-form-group label-for="website" label="Website">
            <b-form-input
              id="website"
              v-model="informationData.website"
              placeholder="Website address"
            />
          </b-form-group>
        </b-col>
        <!--/ website -->

        <!-- phone -->
        <b-col md="6">
          <b-form-group label-for="phone" :label="$t('Form.Phone')" >
            <b-form-input id="mobile" v-model="formatPhone" />
          </b-form-group>
        </b-col>
        <!-- phone -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="saveChanges"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <ToastNotification ref="toast" />
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

import ToastNotification from "@/components/ToastNotification";

import axiosU from "@/services/admin/user";
import { fromConfig } from "@/mixins/configFormatCal";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    ToastNotification,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      countryOption: ["USA", "India", "Canada"],
      config: fromConfig({ dateFormat: "m-d-Y", }),
    };
  },
  computed: {
    formatPhone: {
      get() {
        let { phone } = this.informationData;
        if (phone) {
          phone = phone.toString().replace(/\D/g, "");
          const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
          if (match) {
            phone = `(${match[1]}${match[2] ? "" : ""}) ${match[2]}${
              match[3] ? "-" : ""
            }${match[3]}`;
          }
          return phone;
        } else {
          return "";
        }
      },
      set(val) {
        this.informationData.phone = val;
      },
    },
  },
  methods: {
    resetForm() {
      this.informationData = this.informationData;
    },
    saveChanges() {
      const phone =
        this.informationData.phone.replace(/[() -]/g, "") ||
        this.informationData.phone;
      axiosU
        .userUpdate(this.informationData.id, { ...this.informationData, phone })
        .then(() => {
          this.$refs.toast.success("User updated successfully");
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          this.$refs.toast.error("Error updating user");
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
